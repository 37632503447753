<template>
  <page-section v-if="promo !== undefined" :margin-top="marginTop">
    <content-column :width="width">
      <variants>
        <template #sm->
          <div :class="['p-fl-md flex flex-col gap-fl-lg border rounded-xl', borderColor]">
            <imgix-image
              v-if="imageConfig !== undefined"
              :config="imageConfig"
              class="w-full rounded-[0.5rem]"
            />
            <div class="flex flex-col justify-center">
              <promotion-copy :promotion-copy="promo.promotionCopy" />
              <div
                v-if="cta !== undefined"
                class="mt-fl-lg flex flex-col gap-fl-md justify-center items-center"
              >
                <standard-button
                  :size="'sm'"
                  :label="cta.text"
                  :url="cta.url"
                  :new-tab="cta.newTab"
                />
              </div>
            </div>
          </div>
        </template>
        <template #md+>
          <div :class="['p-fl-md flex border rounded-xl border-dark', borderColor]">
            <div class="basis-1/2 pr-fl-3xs-3xl flex flex-col justify-center">
              <imgix-image
                v-if="imageConfig !== undefined"
                :config="imageConfig"
                class="rounded-[0.5rem]"
              />
            </div>
            <div class="grow-0 basis-1/2 flex flex-col justify-center">
              <promotion-copy :promotion-copy="promo.promotionCopy" />
              <div v-if="cta !== undefined" class="mt-fl-lg flex gap-fl-xl items-center">
                <standard-button
                  :size="'sm'"
                  :label="cta.text"
                  :url="cta.url"
                  :new-tab="cta.newTab"
                />
              </div>
            </div>
          </div>
        </template>
      </variants>
    </content-column>
  </page-section>
</template>

<script lang="ts">
import {defineComponent, computed, PropType} from 'vue';
import Variants from '../../../core/column-layout/Variants.vue';
import PageSection, {PageSectionMargin} from '../../../core/page/PageSection.vue';
import ContentColumn from '../../../core/compositions/ContentColumn.vue';
import StandardButton from '../../../core/button/StandardButton.vue';
import PromotionCopy from '../../partials/PromotionCopy.vue';
import ImgixImage from '../../../core/imgix-image/ImgixImage.vue';
import {PromotionComposition} from '../../../vue-composition/promotion/promotion';
import {StandardPageAreaWidth} from '../../../core/column-layout/utils';
import {PromotionLocation} from '../../../../backend/promotion/promotion-types';
import {getImageConfig} from '../../../../backend/content/content-entry/content-entry-query-utils';
import {ImgixImageResponsiveWidth} from '../../../core/imgix-image/types';
import {usePromoColors} from '../../../vue-composition/promotion/promo-colors';

export default defineComponent({
  components: {
    Variants,
    PageSection,
    ContentColumn,
    PromotionCopy,
    ImgixImage,
    StandardButton
  },
  props: {
    location: {type: String as PropType<PromotionLocation>, required: true},
    promotions: {type: Object as PropType<Readonly<PromotionComposition>>, required: true},
    width: {type: String as PropType<StandardPageAreaWidth>, default: 'medium'},
    marginTop: {type: String as PropType<PageSectionMargin>, default: 'xl'}
  },
  setup(props) {
    const promo = computed(() => {
      return props.promotions.get(props.location);
    });
    const imageConfig = computed(() => {
      if (promo.value === undefined) {
        return undefined;
      }
      const width: Readonly<ImgixImageResponsiveWidth> = {
        narrow: 520,
        sm: 650,
        md: 400,
        lg: 500,
        xl: 600,
        '2xl': 560
      };
      return getImageConfig(promo.value.imageCollection, ['cover'], 'Promotion', width, {
        ar: '15:9',
        fit: 'crop'
      });
    });
    const cta = computed(() => {
      if (promo.value === undefined) {
        return undefined;
      }
      if (promo.value.promotionCallToAction.length === 0) {
        return undefined;
      }
      const cta = promo.value.promotionCallToAction[0];
      const text = cta.promotionCtaText;
      const url = cta.promotionCtaUrl;
      const newTab = cta.promotionCtaOpenInNewTab;

      return {text, url, newTab};
    });

    const {borderColor} = usePromoColors(promo);

    return {
      promo,
      imageConfig,
      cta,
      borderColor
    };
  }
});
</script>
