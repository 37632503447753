<template>
  <button
    :disabled="disabled"
    :class="`flex justify-center items-center flex-nowrap ${cfg} cursor-pointer select-none`"
    :title="labelConfig.tooltipText.value"
    :aria-label="labelConfig.ariaLabel.value"
    @click.prevent="clicked"
  >
    <font-awesome-icon v-if="icon" :icon="icon" aria-hidden="true"></font-awesome-icon>
    <div class="whitespace-nowrap">{{ labelConfig.labelText }}</div>
  </button>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {CraftUrl} from '../../../backend/craft/craft-types';
import {ColorConfig} from '../color-config';
import {
  ButtonSize,
  getClickFunc,
  getStandardColor,
  getStandardDimensions,
  getLabel,
  ButtonColor,
  ButtonClickCallback
} from './implementation/utils';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

/**
 * A styled, basic button with a label.
 */
export default defineComponent({
  components: {
    FontAwesomeIcon
  },
  props: {
    disabled: {type: Boolean, default: false},

    size: {type: String as PropType<ButtonSize>, default: 'md'},
    /** If true, set the width of the button to 100%. */
    expand: {type: Boolean, default: false},

    color: {type: String as PropType<ButtonColor>, default: 'white'},
    /** Optional spot color; overrides the 'color' prop. */
    spotColor: {type: Object as PropType<Readonly<ColorConfig>>, default: undefined},

    label: {type: String, required: true},
    tooltip: {type: String, default: undefined},

    /** FontAwesome icon (positioned left of the label). */
    icon: {type: Object as PropType<Readonly<IconDefinition>>, default: undefined},

    /** The gap between the icon and the label. */
    gap: {type: String, default: 'gap-2'},
    /** Set this to override the flex justification. */
    justify: {type: String, default: 'justify-center'},

    /** Optional ID number to pass to the 'onClick' function. */
    id: {type: Number, default: undefined},
    /** If defined, call this function (with the button id) when the button is clicked. */
    onClick: {type: Function as PropType<ButtonClickCallback>, default: undefined},

    /** If defined, navigate to this URL when the button is clicked. */
    url: {type: String as PropType<CraftUrl>, default: undefined}
  },
  setup(props) {
    const clicked = getClickFunc(props.onClick, props.id, props.url);
    const color = computed(() => {
      return getStandardColor(props.disabled, props.color, props.spotColor);
    });
    const labelConfig = computed(() => getLabel(props.label, props.tooltip));
    const dim = computed(() => getStandardDimensions(props.size, props.expand));

    const cfg = computed(() => {
      return `${dim.value} ${props.justify} ${props.gap} ${color.value.textColor} ${color.value.bgColor} border-2 rounded-[0.5rem] ${color.value.borderColor}`;
    });

    return {
      cfg,
      labelConfig,
      clicked
    };
  }
});
</script>
